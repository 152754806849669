export const LOADING = 'LOADING';

export const COMMENT_CURSOR = 'COMMENT_CURSOR';
export const SHOW_COMMENT = 'SHOW_COMMENT';
export const HIDE_COMMENT = 'HIDE_COMMENT';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const CHART_PERIOD = 'CHART_PERIOD';

export const PERIOD_TYPE = 'PERIOD_TYPE';
export const VIEW_TYPE = 'VIEW_TYPE';

export const SELECTED_ROWS = 'SELECTED_ROWS';
export const HIDEFORECASTEDITINGMODAL = 'HIDEFORECASTEDITINGMODAL';

export const LIST_VIEW_TYPE = 'LIST_VIEW_TYPE';
export const LIST_SHOW_SYMBOL = 'LIST_SHOW_SYMBOL';

export const LIST_ADDED_FIELDS = 'LIST_ADDED_FIELDS';
export const LIST_REMOVED_FIELDS = 'LIST_REMOVED_FIELDS';
export const LIST_HIGHLIGHT = 'LIST_HIGHLIGHT';
